import { render, staticRenderFns } from "./constructionForm.vue?vue&type=template&id=539bbbf6&scoped=true&"
import script from "./constructionForm.vue?vue&type=script&lang=js&"
export * from "./constructionForm.vue?vue&type=script&lang=js&"
import style0 from "./constructionForm.vue?vue&type=style&index=0&id=539bbbf6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539bbbf6",
  null
  
)

export default component.exports